<template>
  <div class="mine">
    <div class="mine_aa">
      个人中心
    </div>
  </div>
  <div class="mine_bb">
    <el-form :model="form" label-width="auto" style="max-width: 600px" >
      <el-form-item label="姓名">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="性别">
        <el-input v-model="form.gender" />
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item label="联系邮箱">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="职务">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="工作单位">
        <el-input v-model="form.company" />
      </el-form-item>
      <el-form-item label="权限状态">
        <span>{{ form.quanxian }}</span>
      </el-form-item>
    </el-form>
    <el-button type="primary" class="mine_submit">更新基本信息</el-button>
  </div>

</template>

<script>
import "../css/mine.css"
export default {
  name: 'MineA',
  props: {
    msg: String
  },


}
</script>
<script setup>
import { reactive } from 'vue'
const form = reactive({
  name: 'qq',
  gender: 'q',
  phone: 'q',
  email: 'q',
  title: 'a',
  company: 'a',
  quanxian: '',
})
</script>
<style></style>