class projectType {
    constructor() {
        this.options = [
            { label: "公建", value: 0 },
            { label: "住宅", value: 1 },
            { label: "市政", value: 2 },
            { label: "水利", value: 3 },
            { label: "其他", value: 4 },
        ];
    }

    getLabel(value) {
        const option = this.options.find(option => option.value === value);
        return option ? option.label : null;
    }

}
export default projectType;