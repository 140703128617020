<template>
    <div class="bb">
        <el-tabs class="demo-tabs2" @tab-click="handleClick2" v-model="activeName2">
            <el-tab-pane :label="`全部任务 ${allCount}`" name="-1">
                <div class="scroll-container">
                    <renwuitemRelease></renwuitemRelease>
                    <!-- <renwuitemRelease v-if="flag == 1"></renwuitemRelease>
                    <renwuitemAccept v-else></renwuitemAccept> -->
                </div>
            </el-tab-pane>
            <el-tab-pane :label="`进行中 ${unfinishedCount}`" name="0">
                <div class="scroll-container">
                    <renwuitemRelease></renwuitemRelease>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="`已完成 ${finishedCount}`" name="1">
                <div class="scroll-container">
                    <renwuitemRelease></renwuitemRelease>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>


export default {
    name: "RenwuList",

    components: {
        renwuitemRelease,
    }
}
</script>
<script setup>
import renwuitemRelease from "./renwuitemRelease.vue"
import "../css/renwulist.css"
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import { ref, onMounted, watch, getCurrentInstance, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import eventBus from '../js/eventBus.js';
const store = useStore()
const route = useRoute()
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
const user = JSON.parse(sessionStorage.getItem("user"))
const { proxy } = getCurrentInstance()
const flag = ref(0);
const allCount = ref(0);
const unfinishedCount = ref(0);
const finishedCount = ref(0);
let allrenwulist = null;
// const rwlist = reactive([]);
// let rwlists=null;
const activeName2 = ref('-1');
const handleClick2 = (e) => {
    store.dispatch('statusfun', e.props.name)
}
onMounted(async () => {
    if (route.query.flag != undefined) {
        flag.value = route.query.flag;  // 从 query 中获取 flag
    }
    await refreshData();
    eventBus.on('taskrefresh', refreshData);

});
watch(() => route.query.flag, async(newFlag) => {
    flag.value = newFlag;
    await refreshData();
});
const refreshData = async () => {
    const urldata = new URLSearchParams();
    urldata.append('Token',getCookie('token'));
    urldata.append('Account', user.emailname);
    urldata.append('ProjectId', projectinfo._id);
    const res = await new proxy.$request(proxy.$urls.m().GetAssigment,urldata).modepost();
    if(!res)return;
    console.log(res)
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            allrenwulist = res.data.ProjectTaskinfo;
            let assignmentlistshow = null;
            //我创建的
            if (flag.value == 1) {
                assignmentlistshow = allrenwulist.filter(item => item.creatorName == user.emailname);
                console.log(assignmentlistshow)
            }
            //我接收的
            else if(flag.value == 2){
                assignmentlistshow = allrenwulist.filter(item => item.accountinfos.includes(user.emailname));
            }
            //项目全部任务
            else{
                assignmentlistshow = allrenwulist;
            }
            sessionStorage.setItem("flag",flag.value);
            allCount.value = assignmentlistshow.length;
            unfinishedCount.value = assignmentlistshow.filter(item => item.status == false).length;
            finishedCount.value = assignmentlistshow.filter(item => item.status == true).length;
            store.dispatch('rwlistfun', assignmentlistshow)
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()
        }
    }
}
onBeforeUnmount(() => {
    // 在组件销毁时取消事件监听
    eventBus.off('taskrefresh', refreshData);
});
</script>
