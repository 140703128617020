const findCheckedKeys = (node, permissionData) => {
    const checkedKeys = [];
    const checkTreeNode = (node) => {
        if (node.value) {
            const found = Object.keys(permissionData).some((category) => {
                return permissionData[category][node.value] === true;
            });

            if (found) {
                checkedKeys.push(node.id);
            }
        }
    };
    node.forEach((rootNode) => {
        checkTreeNode(rootNode);
    });

    return checkedKeys;
};

// 处理钩子，自动比对并选中
const handleAutoCheck = (treeRef, allnode, permissionData) => {
    const checkedKeys = findCheckedKeys(allnode, permissionData);
    if (checkedKeys.length != 0) {
        treeRef.value.setCheckedKeys(checkedKeys);
    }

};
export default handleAutoCheck;