<template>
    <div class="preview_title">
        <el-icon size="25"><ArrowLeftBold @click="CloseView"/></el-icon>
        <div class="preview_text">
            <span>{{msg}}</span>
        </div>
       
    </div>
    <div class="docx-preview-wrap">
        <!-- <input type="file" @change="upload" /> -->
        <div id="bodyContainer"></div>
    </div>
</template>
<script>
export default {
    name: 'DocxViewer',
    props: {
        msg: String
    },

}
</script>
<script setup>
import { renderAsync } from 'docx-preview';
import { onMounted ,ref} from 'vue';
import fileConvert from '@/js/fileConvert.js';
import { ElLoading } from 'element-plus'
import { useRouter } from 'vue-router'
const router = useRouter();
const fileconvert = new fileConvert();
const msg=ref('预览')
let loadingInstance=null;
onMounted(() => {
    loadingInstance = ElLoading.service({
        target:"#bodyContainer",
        lock: true,
    })
    let bodyContainer = document.getElementById("bodyContainer");
    const fileinfo = JSON.parse(sessionStorage.getItem('fileinfo'))
    msg.value = fileinfo.FileName;
    const url = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(fileinfo.FilePath);
    
    // 使用 XMLHttpRequest 以便获取进度
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';

    xhr.onprogress = (event) => {
        if (event.lengthComputable) {
            // 计算进度百分比
            loadingInstance.setText (`加载中：${Math.floor((xhr.loaded / (xhr.total * (1.0))) * 100)}%`);
        }
    };

    xhr.onload = () => {
        if (xhr.status === 200) {
            renderAsync(xhr.response, bodyContainer, null, docxOptions).then(() => {
                loadingInstance.close(); // 关闭 loading 实例
            });
        } else {
            console.error('加载文档出错:', xhr.statusText);
            loadingInstance.close();
        }
    };

    xhr.onerror = () => {
        console.error('请求错误');
        loadingInstance.close();
    };

    xhr.send();
})
const CloseView=()=>{
    loadingInstance.close();
    router.back();//浏览器历史回退
}
const docxOptions = {
    className: "kaimo-docx-666", // string：默认和文档样式类的类名/前缀
    inWrapper: true, // boolean：启用围绕文档内容的包装器渲染
    ignoreWidth: false, // boolean：禁用页面的渲染宽度
    ignoreHeight: false, // boolean：禁止渲染页面高度
    ignoreFonts: true, // boolean：禁用字体渲染
    breakPages: false, // boolean：在分页符上启用分页
    ignoreLastRenderedPageBreak: true, // boolean：在 lastRenderedPageBreak 元素上禁用分页
    experimental: false, // boolean：启用实验功能（制表符停止计算）
    trimXmlDeclaration: true, // boolean：如果为true，解析前会从 xml 文档中移除 xml 声明
    useBase64URL: false, // boolean：如果为true，图片、字体等会转为base 64 URL，否则使用URL.createObjectURL
    useMathMLPolyfill: false, // boolean：包括用于 chrome、edge 等的 MathML polyfill。
    showChanges: false, // boolean：启用文档更改的实验性渲染（插入/删除）
    debug: true, // boolean：启用额外的日志记录
};


</script>
<style scoped>
.docx-preview-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* 确保子元素垂直布局 */
}

#bodyContainer {
    height: 100%;
    flex-grow: 1;
    /* 允许容器在可用空间中增长 */
    overflow-y: auto;
    /* 允许垂直滚动 */
    padding: 5px;
    /* 可选：添加一些内边距，防止内容和边缘粘连 */

}
.preview_title{
    height: 30px;
    font-size: 15px;
    display: flex;
    background-color: #ffffff;

}
.preview_text {
    width: calc(100vw - 30px); /* 使用100vw来代表视口宽度 */
    text-align: center; /* 保持文本右对齐 */
}
</style>
