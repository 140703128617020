
// 设置 Cookie
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"; // Set path
  }
  
  // 读取 Cookie
  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null; // Not found
  }
  
  // 删除 Cookie
  function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999; path=/'; // Set path to ensure the cookie is deleted
  }
  
  // 导出函数
  export { setCookie, getCookie, eraseCookie };
  