<template>
	<div class="preview_title">
		<el-icon size="25">
			<ArrowLeftBold @click="CloseView" />
		</el-icon>
		<div class="preview_text">
			<span>{{ msg }}</span>
		</div>

	</div>
	<iframe :src="pptUrl" class="preview_content"></iframe>
</template>
<script>
export default {
	name: 'PdfViewer',
	props: {
		msg: String
	},
}
</script>
<script setup>
import { ref, onMounted } from 'vue';
import fileConvert from '@/js/fileConvert.js';
import { useRouter } from 'vue-router'
const router = useRouter();
const fileconvert = new fileConvert();
const pptUrl = ref('')
const msg=ref('预览')
onMounted(() => {
	const fileinfo = JSON.parse(sessionStorage.getItem('fileinfo'))
	msg.value=fileinfo.FileName
	const url = "https://bim.bfine-tech.com" + fileconvert.convertWindowsPathToUnixPath(fileinfo.FilePath)
	pptUrl.value = `/ppt/index.html?file=${url}`;
})
const CloseView=()=>{
    router.back();//浏览器历史回退
}

</script>
<style scoped>
.m-ppt-wrap {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.m-iframe {
	width: 100%;
	flex: 1;
	border: none;
}
.preview_title{
    height: 30px;
    font-size: 15px;
    display: flex;
    background-color: #ffffff;

}
.preview_text {
    width: calc(100vw - 30px); /* 使用100vw来代表视口宽度 */
    text-align: center; /* 保持文本右对齐 */
}
.preview_content{
	width:100vw;
	height:97vh;
}
</style>
