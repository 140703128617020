// 使用 Blob 转换为字节数组（Uint8Array）
// const blobToBytes = (blob) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             // onloadend 触发时，result 会是 ArrayBuffer 对象
//             const arrayBuffer = reader.result;
//             // 将 ArrayBuffer 转换为 Uint8Array 字节数组
//             const byteArray = new Uint8Array(arrayBuffer);
//             resolve(byteArray);  // 返回字节数组
//         };
//         reader.onerror = reject;  // 处理失败情况
//         reader.readAsArrayBuffer(blob);  // 读取 Blob 并产生 ArrayBuffer
//     });
// };
function blobToBytes(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // 获取 Base64 字符串
      reader.onerror = reject;
      reader.readAsDataURL(file); // 读取文件为 Base64
    });
  }
export default blobToBytes;