import { createStore } from 'vuex';

export default createStore({
  state: {
    emailname: "",
    editrenwu: false,
    prolisttype: "first",
    typeselected:0,
    searchkey:"",
    rwlist:[],
    status:-1,
    noticeitemStatus:"first"
  },
  mutations: {
    emailfun1(state, val) {
      state.emailname = val;
    },
    prolisttypefun1(state, val) {
      state.prolisttype = val;
    },
    typeselectfun1(state, val) {
      state.typeselected = val;
    },
    searchclickfun1(state, val) {
      state.searchkey = val;
    },
    rwlistfun1(state, val) {
      state.rwlist = val;
    },
    statusfun1(state, val) {
      state.status = val;
    },
    noticeitemStatusfun1(state, val) {
      state.noticeitemStatus = val;
    },
  },
  actions: {
    emailfun({ commit }, emailname) {
      commit('emailfun1', emailname);
    },
    prolisttypefun({ commit }, prolisttype) {
      commit('prolisttypefun1', prolisttype);
    },
    typeselectfun({ commit }, typeselected) {
      commit('typeselectfun1', typeselected);
    },
    searchclickfun({ commit }, searchkey) {
      commit('searchclickfun1', searchkey);
    },
    rwlistfun({ commit }, rwlist) {
      commit('rwlistfun1', rwlist);
    },
    statusfun({ commit }, status) {
      commit('statusfun1', status);
    },
    noticeitemStatusfun({ commit }, noticeitemStatus) {
      commit('noticeitemStatusfun1', noticeitemStatus);
    },
  }
});
