const ERROR_CODES = {
    200001: "请求重复",
    200002: "登录信息为空",
    200003: "账号格式错误",
    200004: "密码格式错误",
    200005: "账号在黑名单中",
    200006: "登录密码错误",
    200007: "其他账号登录中",
    200008: "游戏网关登录错误",
    200009: "会话玩家错误",
    200010: "没有玩家",
    200011: "重新进入游戏错误",
    200012: "玩家会话错误",
    200013: "重新进入游戏错误",
    200014: "进入游戏错误",
    200015: "Token错误,请重新登录",
    200016: "角色名称为空",
    200017: "角色名称相同",
    200018: "角色不存在",
    200019: "该账户不存在",
    200020: "电话为空",
    200021: "账号已存在",
    200022: "电话和邮箱都为空",
    200023: "BIMAR集成已存在",
    200024: "BIMAR集成不存在",
    200025: "邀请人已存在",
    200026: "邀请人没有权限",
    200027: "没有创建权限",
    200028: "项目数量不足",
    200029: "经理为空",
    200030: "人群已满",
    200031: "项目分配已存在",
    200032: "未加入BIMAR集成",
    200033: "项目分配不存在",
    200034: "项目分配无权限",
    200035: "文件未找到",
    200036: "角色权限配置已存在",
    200037: "角色未找到",
    200038: "VIP 状态未找到",
    200039: "没有 AR Smart Cloud",
    200040: "没有 VIP 状态",
    200041: "无删除权限",
    200042: "角色编号已存在",
    200043: "无邀请者权限",
    200044: "角色已存在",
    200045: "用户未找到",
    200046: "无上传文件权限",
    200047: "角色配置不存在",
    200048: "文件名无效",
    200049: "文件夹名称无效",
    200050: "文件夹命名不合法",
    200051: "文件夹名称已找到",
    200052: "文件夹名称相同",
    200053: "任务已完成,暂不可修改",
    200054: "文件格式错误",
    200055: "没有信息消息",
    200056: "项目已创建",
    200057: "没有存储空间"


};
const handleError = (res) => {
    if (res.data && res.data.Error) {
        const errorCode = res.data.Error;
        return ERROR_CODES[errorCode] || "未知错误"; // 若未定义的错误码则返回"未知错误"
    }
    return "没有返回错误信息"; // 如果没有返回错误信息
}
export default handleError;
