<template>
  <div class="bimtitle">
    <el-image :src="Navigatelogourl" fit="fill" class="logo" />
    <label class="filename">{{ filename }}&nbsp;&nbsp;{{ file_version }}</label>
    <el-image :src="Closeurl" fit="fill" @click="CloseBimtitle" class="title_icon_style"></el-image>
  </div>
  <div class="bimout">
    <div id="my-three"></div>
  </div>
  <div class="left_out" v-if="isLeftVisible"
    :style="{ width: containerWidth + 'px', height: containerHeight + 'px', left: positionleft.x + 'px', top: positionleft.y + 'px' }">
    <div class="left_inner" style="width: 100%; height: 100%;">
      <div class="left">
        <div class="goujianTitle" @mousedown="startDragplus">
          <div class="goujianText">构件列表</div>
          <el-icon @click="CloseList" class="icon_style">
            <Close />
          </el-icon>
        </div>
        <div class="lineview"></div>
        <div class="searchkeyinput"> <el-input v-model="searchkeyinput" placeholder="搜索构件" clearable @input="filterTree"
            prefix-icon="Search" /></div>
        <el-tree :data="GLBdata" :props="defaultProps" class="leftContent" @node-click="handleNodeClick"
          :filter-node-method="filterNode" ref="treeRef" :expand-on-click-node="false" highlight-current="true">
          <template #default="{ node, data }">
            <div class="tree-node-container">
              <span>{{ node.label }}</span>
              <el-image class="icon_style" :src="data.modelShowflag === true ? ModelShow : ModelHide"
                @click.stop="handleButtonClick(data)" />
            </div>
          </template>
        </el-tree>

      </div>
      <div class="controlline_leftbottom" @mousedown="startDrag_leftbottom"></div>
    </div>
    <div class="controlline" @mousedown="startDrag">

    </div>
  </div>

  <div class="right_out" v-if="flag == 1"
    :style="{ width: containerWidth_right + 'px', height: containerHeight_right + 'px', right: positionright.x + 'px', top: positionright.y + 'px' }">
    <div class="controlline_right" @mousedown="startDrag_right">
    </div>
    <div class="right_inner" style="width: 100%; height: 100%;">
      <div class="right">
        <div class="FamilyNameTitle" @mousedown="startDragplus_right">
          <div class="FamilyName">属性列表</div>
          <el-icon @click="CloseListFamilyName" class="icon_style">
            <Close />
          </el-icon>
        </div>
        <div class="lineview"></div>
        <div class="demo-collapse">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(group, index) in leaf_Parameters" :key="index" :name="group.GroupName"
              class="custom-collapse-item">
              <template #title>
                <div class="collapse-title">
                  <el-icon class="icon-left">
                    <template v-if="activeNames.includes(group.GroupName)">
                      <CaretBottom />
                    </template>
                    <template v-else>
                      <CaretRight />
                    </template>
                  </el-icon>
                  <span>{{ group.GroupName }}</span>
                </div>
              </template>
              <template #default>
                <el-descriptions column="1" size="Default" class="custom-descriptions">
                  <el-descriptions-item v-for="(param, paramIndex) in group.Parameters" :key="paramIndex"
                    :label="param.name" label-class-name="el-descriptions-item">{{ param.value }}</el-descriptions-item>

                </el-descriptions>
              </template>

            </el-collapse-item>

          </el-collapse>
        </div>

      </div>
      <div class="controlline_rightbottom" @mousedown="startDrag_rightbottom"></div>
    </div>



  </div>

  <div class="group1">
    <div @click="styleclick">
      <el-image :src="pouqieurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>主页</div>
    </div>
    <div @click="returnmodel">
      <el-image :src="modelurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>原模型</div>
    </div>
    <div @click="pouqieclick">
      <el-image :src="styleurl" fit="fill" class="icon_style"></el-image>
      <div>剖切</div>
    </div>
    <div @click="measureclick">
      <el-image :src="measureurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>测量</div>
    </div>
  </div>

  <div class="group2">
    <div @click="rotateclick">
      <el-image :src="rotateurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>旋转</div>
    </div>
    <div @click="treeclick">
      <el-image :src="treeurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>构件树</div>
    </div>
    <div @click="atrributeclick">
      <el-image :src="atrributeurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>属性</div>
    </div>
    <div @click="gridHelperclick">
      <el-image :src="gridHelperurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>网格</div>
    </div>
    <div @click="axisHelperclick">
      <el-image :src="axisHelperurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>坐标轴</div>
    </div>
    <div @click="bgcclick">
      <el-image :src="bgcurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>背景</div>

    </div>
    <div @click="fullsceneclick">
      <el-image :src="fullsceneurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>全景</div>
    </div>
    <div @click="fullscreenclick">
      <el-image :src="fullscreenurl" alt="" fit="fill" class="icon_style"></el-image>
      <div>全屏</div>
    </div>
  </div>

  <div class="group3">
    <div @click="wireframeclick">
      <el-image :src="selectedImage === 1 ? selectedWireframeUrl : wireframeurl" fit="fill"
        class="icon_style"></el-image>
      <div>线框</div>
    </div>
    <div @click="reallyclick">
      <el-image :src="selectedImage === 2 ? selectedReallyUrl : reallyurl" alt="" fit="fill"
        class="icon_style"></el-image>
      <div>真实</div>
    </div>
    <div @click="colorclick">
      <el-image :src="selectedImage === 3 ? selectedColorUrl : colorurl" alt="" fit="fill"
        class="icon_style"></el-image>
      <div>着色</div>
    </div>
  </div>

  <el-progress v-if="view_loading" :percentage="percent" :stroke-width="15" striped-flow />
  <el-dialog v-model="bgcdialog" title="请选择" width="500" venter>
    <el-radio-group v-model="bgc">
      <el-radio value="color">背景颜色</el-radio> <el-color-picker v-model="bgccolor" show-alpha />
      <!-- <el-radio value="image">背景图片</el-radio><el-upload action="#" list-type="picture-card" limit="4"
        :auto-upload="false" :on-change="beforeUpload">
        <el-icon>
          <Plus />
        </el-icon>
      </el-upload> -->
    </el-radio-group>


    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="bgcConfirm">确定</el-button>
        <el-button @click="bgcCancel">
          取消
        </el-button>
      </div>
    </template>
  </el-dialog>
  <!-- <el-button type="primary" @click="chexiao" style="position: absolute;top:100px;left:100px;">测量撤销</el-button> -->
  <div ref="statsContainer" class="stats"></div>
</template>

<script>
export default {
  name: 'BimViewer',
  props: {
    msg: String
  },
}
</script>
<script setup>
import * as THREE from 'three';
import "../css/bimviewer.css"
import { ref, onMounted, getCurrentInstance, onBeforeUnmount } from 'vue'
import Initialize from "../js/Initialize.js";
import Simulation from "../js/simulation.js";
import transformToReactiveStructure from "../js/transformToReactiveStr.js"
import NodeClick from "../js/NodeClick.js"
import CanvasOperation from "../js/CanvasOperation.js"
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import ModelShow from '@/img/modelshow.svg';
import ModelHide from '@/img/modelhide.svg';
import { useRouter } from 'vue-router'
import eventBus from '../js/eventBus.js';
// import fileConvert from '@/js/fileConvert.js';
import { ElLoading } from 'element-plus'
import _ from 'lodash';
import Stats from 'three/addons/libs/stats.module.js';
const router = useRouter();
const { proxy } = getCurrentInstance()
const filename = ref();
const pouqieurl = ref(require('@/img/pouqie2.svg'));
const styleurl = ref(require('@/img/style2.svg'))
const measureurl = ref(require('@/img/measure2.svg'))
const rotateurl = ref(require('@/img/rotate2.svg'))
const bgcurl = ref(require('@/img/bgc2.svg'))
const fullsceneurl = ref(require('@/img/fullscene2.svg'))
const fullscreenurl = ref(require('@/img/fullscreen2.svg'))
const Closeurl = require('@/img/Close.svg')
const Navigatelogourl = require('@/img/72-2.png')
const gridHelperurl = ref(require('@/img/mesh2.svg'))
const wireframeurl = ref(require('@/img/wireframe2.svg'))
const reallyurl = ref(require('@/img/really2.svg'))
const colorurl = ref(require('@/img/color2.svg'))
const selectedWireframeUrl = ref(require('@/img/wireframe.svg'))
const selectedReallyUrl = ref(require('@/img/really.svg'))
const selectedColorUrl = ref(require('@/img/color.svg'))
const treeurl = ref(require('@/img/tree2.svg'))
const atrributeurl = ref(require('@/img/atrribute2.svg'))
const axisHelperurl = ref(require('@/img/axis.svg'))
const modelurl = ref(require('@/img/model.svg'))
const selectedImage = ref(2)
const statsContainer = ref(null);
const defaultProps = {
  children: 'children',
  label: 'label',
  class: customClass
}
const bgcdialog = ref(false)
const bgc = ref('color')
const { scene, camera, renderer, controls, directionalLight, gradientMesh } = Initialize();
let GLBdata = ref()
let flag = ref(0)
let isLeftVisible = ref(false);
let leaf_Parameters = ref([])
let model = null;
const activeNames = ref([])
const view_loading = ref(false)
const bgccolor = ref('#ffffff')
// const bgc_fileList = [];
const percent = ref(0)
const searchkeyinput = ref('')
const treeRef = ref(null)
const file_version = ref()
const Nodeclick = new NodeClick(scene);
// const fileconvert = new fileConvert();
const Canvasoperation = new CanvasOperation(camera, scene, renderer, controls);
const center = new THREE.Vector3();//原始模型的中心点
let loadingInstance = null;//遮罩层
const containerWidth = ref(window.innerWidth * 0.15)
const containerHeight = ref(window.innerHeight - 68)
const containerWidth_right = ref(window.innerWidth * 0.15)
const containerHeight_right = ref(window.innerHeight - 68)
const positionleft = ref({ x: 0, y: 68 }); // 初始位置
const positionright = ref({ x: 0, y: 68 }); // 初始位置
let startOffset = { x: 0, y: 0 };
let startPosition = 0;
let startPosition2 = 0;
let isDragging_div = false;
let type = 0;
const onMouseMove = (event) => {
  if (isDragging_div) {
    const delta = event.clientX - startPosition;
    const delta2 = startPosition2 - event.clientY;
    switch (type) {
      case 1://左：左右拉伸
        containerWidth.value = Math.max(window.innerWidth * 0.15, containerWidth.value + delta);
        break;
      case 2://右：左右拉伸
        containerWidth_right.value = Math.max(window.innerWidth * 0.15, containerWidth_right.value - delta);
        break;
      case 3://左：上下拉伸
        {
          containerHeight.value = Math.max(window.innerHeight * 0.20, containerHeight.value - delta2);
          break;
        }
      case 4://右：上下拉伸
        containerHeight_right.value = Math.max(window.innerHeight * 0.20, containerHeight_right.value - delta2);
        break;
      case 5://左：移动
        positionleft.value.x = Math.max(0, event.clientX - startOffset.x);
        positionleft.value.y = Math.max(0, event.clientY - startOffset.y); break;
      case 6://右：移动
        positionright.value.x = Math.max(0, (event.clientX - startOffset.x) * (-1));
        positionright.value.y = Math.max(0, event.clientY - startOffset.y); break;

    }
    startPosition = event.clientX; // 更新起始位置
    startPosition2 = event.clientY; // 更新起始位置
  }
}

const startDrag = (event) => {
  type = 1;
  startPosition = event.clientX;
  temp_event();
};
const startDrag_right = (event) => {
  type = 2;
  startPosition = event.clientX;
  temp_event();
}
const startDrag_leftbottom = (event) => {
  type = 3;
  startPosition2 = event.clientY;
  temp_event();
}
const startDrag_rightbottom = (event) => {
  type = 4;
  startPosition2 = event.clientY;
  temp_event();
}
const startDragplus = (event) => {
  type = 5;
  startOffset.x = event.clientX - positionleft.value.x;
  startOffset.y = event.clientY - positionleft.value.y;
  temp_event();
};

const startDragplus_right = (event) => {
  type = 6;
  startOffset.x = event.clientX - positionright.value.x;
  startOffset.y = event.clientY - positionright.value.y;
  temp_event();
};
const temp_event = () => {
  isDragging_div = true;
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', stopDrag);
}
const stopDrag = () => {
  isDragging_div = false;
  startPosition = 0;
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', stopDrag);
}
let stats = null;
// 用于存储可以被拾取的对象
const pickableObjects = [];
let distance = null;
onMounted(async () => {
  // 创建性能监视器
  stats = new Stats();
  // 将监视器添加到页面中
  statsContainer.value.appendChild(stats.dom);
  //初始化
  const fileinfo = JSON.parse(sessionStorage.getItem('fileinfo'))
  filename.value = fileinfo.FileName
  file_version.value = fileinfo.FileVersion
  //Vue 组件在 setup() 函数中，还未完成 DOM 的挂载，导致可能无法获取 DOM 节点。
  document.getElementById('my-three').appendChild(renderer.domElement);//将画布插入到my-three中，renderer.domElement是渲染的结果
  loadingInstance = ElLoading.service({
    target: '#my-three',
    lock: true,
  })
  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('././draco/');
  dracoLoader.setWorkerLimit(2); // 使用4个解码线程
  loader.setDRACOLoader(dracoLoader);
  // loader.load('./1.0.glb', function (gltf) {
    loader.load("https://oss.bfine-tech.com/" + fileinfo.OssBucketPath, function (gltf) {
    model = gltf.scene;
    const data = gltf.parser.json;
    console.log(model, data)
    GLBdata.value = transformToReactiveStructure(data.nodes);
    console.log(GLBdata.value)
    // 计算模型的包围盒
    const box = new THREE.Box3().setFromObject(model.clone());
    // 计算包围盒的中心
    box.getCenter(center);
    // 将模型的中心移动到原点
    model.position.sub(center);
    model.updateMatrixWorld(true);//更新物体及其后代的全局变换
    const boxSize = new THREE.Vector3();
    box.getSize(boxSize); // 获取包围盒的大小
    // 设置相机的位置
    distance = Math.max(boxSize.x, boxSize.y, boxSize.z) * 1.5;
    camera.position.set(center.x, center.y, center.z + distance);  // 根据包围盒中心的位置和距离设置相机
    Nodeclick.shadowMap(model);
    directionalLight.position.set(50, 100, 50);
    const geometriesMap = new Map(); // 用来存储几何体和对应的变换
    const materialMap = new Map(); // 保存几何体对应材质信息
    const userdataMap = new Map(); // 保存几何体对应材质信息
    const meshesToRemove = []; // 记录需要转换为 InstancedMesh 的对象
    const nameMap = new Map();
    // 遍历模型，寻找重复的几何体
    model.traverse((child) => {
      if (child.isMesh) {
        const geometryKey = child.geometry; // 几何体作为 key
        const materialKey = child.material; // 材质作为 key

        // 确认几何体与材质的组合，如果存在重复，存储其变换
        if (!geometriesMap.has(geometryKey)) {
          geometriesMap.set(geometryKey, []);
          materialMap.set(geometryKey, materialKey); // 将材质与几何体相关联
          userdataMap.set(geometryKey, []); // 修改为存储多个用户数据
          nameMap.set(geometryKey, []); // 修改为存储多个名称

        }
        // 获取每个物体的变换矩阵
        const matrix = new THREE.Matrix4();
        child.updateMatrixWorld(true);
        matrix.copy(child.matrixWorld);
        geometriesMap.get(geometryKey).push(matrix);//将相同geometry的变换矩阵添加到数组中
        const object = Nodeclick.findParameters(child);
        userdataMap.get(geometryKey).push(object.userData.Parameters); // 针对每个实例存储用户数据
        nameMap.get(geometryKey).push(object.name); // 针对每个实例存储名称
        meshesToRemove.push(child); // 记录这个对象，将其从模型树中移除
      }


    });
    // 构建 InstancedMesh，替换重复的 Mesh
    geometriesMap.forEach((matrices, geometryKey) => {
      const material = materialMap.get(geometryKey);
      const instancedMesh = new THREE.InstancedMesh(geometryKey, material, matrices.length);
      const instanceNames = [];
      const instanceUserData = [];
      matrices.forEach((matrix, index) => {
        instancedMesh.setMatrixAt(index, matrix); // 为每个实例设置其世界变换矩阵
        const instanceName = nameMap.get(geometryKey)[index]; // 获取当前实例的名称
        const instanceData = userdataMap.get(geometryKey)[index]; // 获取当前实例的用户数据
        instanceNames.push(instanceName); // 保存到实例名数组
        instanceUserData.push(instanceData); // 保存到用户数据数组
      });
      instancedMesh.userData.array = instanceUserData;
      instancedMesh.userData.name = instanceNames;
      // 保存原始矩阵到 userData
      const savedMatrices = matrices.map(matrix => matrix.clone());
      instancedMesh.userData.savedMatrices = savedMatrices; // 保存
      instancedMesh.computeBoundingBox();                //计算矩形边界
      instancedMesh.computeBoundingSphere();            //计算球形边界
      instancedMesh.instanceMatrix.needsUpdate = true    //通知渲染器需要更新
      instancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage); // 如果需要频繁更新 Matrix，提升性能
      pickableObjects.push(instancedMesh); // 将 InstancedMesh 添加到可拾取对象数组中
      scene.add(instancedMesh); // 将 InstancedMesh 添加到场景中
    });
    // meshesToRemove.forEach((mesh) => {
    //   mesh.parent.remove(mesh); // 将 Mesh 从模型树中移除
    // });

    // scene.add(model);
    Canvasoperation.pickableObjects = pickableObjects;
    loadingInstance.close()
    // console.log(meshesToRemove)

  }, function (xhr) {
    loadingInstance.setText(`加载中：${Math.floor((xhr.loaded / (xhr.total * (1.0))) * 100)}%`)

  });


})
onBeforeUnmount(() => {
  if (statsContainer.value && stats) {
    statsContainer.value.removeChild(stats.dom);
  }
  stats = null; // 清理引用
});
const handleButtonClick = (data) => {
  console.log(data)
  Nodeclick.handleButtonClick(data);
}
let isClick = false; // 是否触发了点击事件标志
const clickobject = []; // 保存几何体对应材质信息,点击状态
let clickTimer = null;
const doubleClickDelay = 300; // 调整此值以设置双击检测的间隔
let lastSelectedObject = null; // 用于存储上一次选择的对象
let temp_instanceid = null;//记录选中的构件name
let lastuuid = null;//记录上一次点击的线框
//鼠标点击选中模型 
renderer.domElement.addEventListener('click', click_material_global)
function click_material_global(event) {
  if (!isClick) return;
  // .offsetY、.offsetX以canvas画布左上角为坐标原点,单位px
  const px = event.offsetX;
  const py = event.offsetY;
  //屏幕坐标px、py转WebGL标准设备坐标x、y
  //width、height表示canvas画布宽高度
  const x = (px / window.innerWidth) * 2 - 1;
  const y = -(py / window.innerHeight) * 2 + 1;
  //创建一个射线投射器`Raycaster`
  const raycaster = new THREE.Raycaster();
  // 通过摄像机和鼠标位置更新射线
  raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
  const intersects = raycaster.intersectObjects(pickableObjects);
  console.log(intersects[0])
  if (intersects.length > 0) {
    const selected = Canvasoperation.clip_clickObject(intersects);
    if (selected == null) return;
    if (clickTimer) {
      // 如果之前的单击计时器还存在，表示这是一个双击
      clearTimeout(clickTimer);
      clickTimer = null; // 清除计时器
      lastSelectedObject = selected.object;
      temp_instanceid = selected.instanceId
      Canvasoperation.animateToCenter(Nodeclick.getBoundingSphereCenterInWorld(selected.object, selected.instanceId));// 处理双击事件
    } else {
      clickTimer = setTimeout(() => {
        clickTimer = null; // 清除计时器
        if (lastSelectedObject == selected.object && selected.instanceId == temp_instanceid) {//两次选择同一个构件，第二次点击会取消点选
          lastSelectedObject = null;
          temp_instanceid = null;
          cleardata();
          return;
        } else {
          lastSelectedObject = selected.object;
          temp_instanceid = selected.instanceId
        }
      }, doubleClickDelay);
    }

    if (selected.object.isInstancedMesh) {
      const instanceId = intersects[0].instanceId;
      if (instanceId !== null) {
        //判断是否是线框模式
        if (selectedImage.value == 1) {
          if (lastuuid) {
            const lines = Nodeclick.InstancedMesh_line[lastuuid];
            if (lines) {
              lines.forEach(line => {
                line.material.color.set(0x000000); // 改变线框的颜色
              });
            }
          }
          const uuid = selected.object.uuid;
          lastuuid = uuid;
          // 获取对应的线框列表
          const lines = Nodeclick.InstancedMesh_line[uuid];
          if (lines) {
            lines.forEach(line => {
              line.material.color.set(0xff0000); // 改变线框的颜色
            });
          }
        }
        else {
          //清除原有的颜色
          if (clickobject.length > 0) {
            if (clickobject[0].length == 2) {
              clickobject[0][0].instanceColor = null;
            }
            else {
              clickobject[0][0].setColorAt(clickobject[0][1], clickobject[0][2]); // 设置旧颜色
              clickobject[0][0].instanceColor.needsUpdate = true; // 通知更新颜色
            }

            clickobject.length = 0;
          }

          const tempArray = []
          tempArray.push(selected.object);
          tempArray.push(instanceId);
          if (selected.object.instanceColor != null) {
            const oldColor = new THREE.Color();
            selected.object.getColorAt(instanceId, oldColor); // 获取旧颜色
            tempArray.push(oldColor);
          }
          clickobject.push(tempArray);
          const tempColor = new THREE.Color(0xff0000); // 红色
          selected.object.setColorAt(instanceId, tempColor); // 设置新颜色
          selected.object.instanceColor.needsUpdate = true; // 通知更新颜色
        }
        console.log(selected.object)
        leaf_Parameters.value = selected.object.userData.array[instanceId];
        activeNames.value = leaf_Parameters.value.map(group => group.GroupName);
      }
    }



  }
  else {//点击空白区域取消选中,清除数据
    cleardata()
    leaf_Parameters.value = null;
  }
}
const cleardata = () => {
  for (let i = 0; i < clickobject.length; i++) {
    if (clickobject[i].length == 2) {
      clickobject[i][0].instanceColor = null;
    }
    else {
      clickobject[i][0].setColorAt(clickobject[i][1], clickobject[i][2]); // 设置旧颜色
      clickobject[i][0].instanceColor.needsUpdate = true; // 通知更新颜色
    }
  }
 
}
renderer.domElement.addEventListener('wheel', Canvasoperation.MouseWheel, { passive: false })
const handleNodeClick = (n) => {
  if (n.isLeaf) {
    console.log(n)
    leaf_Parameters.value = n.modelInfo;
    flag.value = 1;
    atrributeurl.value = require('@/img/atrribute.svg')
    atrribute = 1;
    // Name.value = n.modelInfo.name;
    activeNames.value = leaf_Parameters.value.map(group => group.GroupName);
  }
  //清除原有的颜色
  cleardata()
  clickobject.length = 0;
  const names = Nodeclick.traverseAndCollectNames(n);
  names.forEach((name) => {
    for (let j = 0; j < scene.children.length; j++) {
      const child = scene.children[j];
      if (child && child.isInstancedMesh) {
        const instanceIndex = child.userData.name.findIndex(item =>
          item.trim().toLowerCase() == name.trim().toLowerCase()
        );
        if (instanceIndex !== -1) {
          const oldColor = new THREE.Color();
          const tempArray = [];
          tempArray.push(child);
          tempArray.push(instanceIndex);
          if (child.instanceColor) {
            child.getColorAt(instanceIndex, oldColor); // 获取实例的当前颜色
            tempArray.push(oldColor);
          }
          clickobject.push(tempArray)
          // 修改颜色为红色
          const tempColor = new THREE.Color(0xff0000);
          child.setColorAt(instanceIndex, tempColor);
          child.instanceColor.needsUpdate = true;
          break;
        }
      }
    }


  });
}
const filterTree = () => {
  treeRef.value.filter(searchkeyinput.value);
}
const filterNode = (value, data) => {
  if (!value) return true;
  return data.label.indexOf(value) !== -1;
}
const CloseList = () => {

  treeurl.value = require('@/img/tree2.svg')
  isLeftVisible.value = false
  tree = 0;

}
const customClass = (node, data) => {
  if (node.level == 1) { // 判断当前节点是否为根节点
    return 'background'; // 返回根节点的特定样式类
  }
  return '';
};
const CloseListFamilyName = () => {
  atrributeurl.value = require('@/img/tree2.svg')
  flag.value = 0
  atrribute = 0;
}

let rotate = 0;
const rotateclick = () => {
  if (rotate == 0) {
    const newcenter = new THREE.Vector3();
    const points = []
    //未选中构件，默认旋转中心是模型中心
    if (clickobject.length === 0) {
      const intersects = Canvasoperation.Centermouseraycaster();
      console.log(intersects, newcenter)
      if (intersects.length > 0) {
        newcenter.copy(intersects[0].point)
        mouseraycaster = false;

      }
      // newcenter.copy(new THREE.Vector3(0, 0, 0))
    }
    //选中构件，默认旋转中心是构件中心
    else {
      for (let i = 0; i < clickobject.length; i++) {
        points.push(Nodeclick.getBoundingSphereCenterInWorld(clickobject[i][0], clickobject[i][1]))
      }
      newcenter.copy(Nodeclick.computeCentroid(points))
    }
    controls.target.copy(newcenter)
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1.5;
    // renderer.domElement.removeEventListener('wheel', Canvasoperation.MouseWheel)
    rotateurl.value = require('@/img/rotate1.svg')
    rotate = 1;
  }
  else {
    controls.autoRotate = false;
    // renderer.domElement.addEventListener('wheel', Canvasoperation.MouseWheel, { passive: false })
    rotateurl.value = require('@/img/rotate2.svg')
    rotate = 0;

  }
}
//剖切
let pouqie = 0
let box_save = null;
//创建包围盒
let simulation = null;
const pouqieclick = () => {
  if (pouqie == 0) {
    eventBus.on('clickStatusRefresh', clickStatusRefresh);
    styleurl.value = require('@/img/style1.svg')
    pouqie = 1;
    simulation = new Simulation(pickableObjects, scene, camera, renderer, controls, box_save);
    console.log(simulation)
  }
  else {
    styleurl.value = require('@/img/style2.svg');
    box_save = simulation.dispose();
    Canvasoperation.box_save = box_save;
    simulation = null;
    pouqie = 0;
  }

}
const clickStatusRefresh = () => {
  isClick = false;
}
//全屏
var full = 0;
const fullscreenclick = () => {
  switch (full) {
    case 0:
      full = 1;
      document.body.requestFullscreen();
      fullscreenurl.value = require('@/img/fullscreen1.svg');
      break;
    case 1:
      full = 0;
      document.exitFullscreen();
      fullscreenurl.value = require('@/img/fullscreen2.svg');
      break;
    default:
      new proxy.$tips("发生错误", "error").Mess_age()
  }

}
let mesh = 0;
let gridHelper = null;
const gridHelperclick = () => {
  if (mesh == 0) {
    gridHelperurl.value = require('@/img/mesh.svg')
    // 添加网格地面
    gridHelper = new THREE.GridHelper(100, 100)
    scene.add(gridHelper)
    mesh = 1;
  }
  else {
    gridHelperurl.value = require('@/img/mesh2.svg')
    scene.remove(gridHelper);
    mesh = 0;
  }
}
let measure = 0;
const measureclick = () => {
  if (measure == 0) {
    measureurl.value = require('@/img/measure1.svg')
    measure = 1;
    Canvasoperation.measure = measure;
    renderer.domElement.removeEventListener('click', click_material_global)
    renderer.domElement.addEventListener('click', measure_click_double)
    renderer.domElement.addEventListener('click', Canvasoperation.measure_delete)
  }
  else {
    measureurl.value = require('@/img/measure2.svg')
    measure = 0;
    clearMeasurements();
    Canvasoperation.dispose()
    renderer.domElement.removeEventListener('mousemove', Canvasoperation.drawLineHandler)
    scene.remove(Canvasoperation.line)
    renderer.domElement.addEventListener('click', click_material_global)
    renderer.domElement.removeEventListener('click', measure_click_double)
  }

}
// const chexiao = () => {
//   if (lines.length == 0) return;
//   scene.remove(lines.pop());
//   Canvasoperation.chexiao();
// }
const clickResults = [];
const maxClicks = 2;
// 存储在场景中添加的对象的数组
const lines = [];
function measure_click_double(event) {
  if (!isClick) return;
  const result = Canvasoperation.rayChoosePoint(event);
  if (result) {
    clickResults.push(result); // 将返回值存储到数组中
    // 检查是否达到最大点击次数
    if (clickResults.length == maxClicks) {
      renderer.domElement.removeEventListener('mousemove', Canvasoperation.drawLineHandler)
      Canvasoperation.temp_remove();

      const p1 = clickResults[0];
      const p2 = clickResults[1];
      const line = Canvasoperation.createLine(p1, p2);
      scene.add(line); // 将线添加到场景中
      lines.push(line); // 存储线条引用
      Canvasoperation.createSprite(p1, p2);
      clickResults.length = 0;

    }
    else {
      Canvasoperation.start = result;
      renderer.domElement.addEventListener('mousemove', Canvasoperation.drawLineHandler)
    }
  }
}
const clearMeasurements = () => {
  // 移除线条
  for (const line of lines) {
    scene.remove(line);
  }
  lines.length = 0; // 清空存储线条的数组



}

const returnmodel = () => {
  renderer.clippingPlanes = [];
  styleurl.value = require('@/img/style2.svg');
  if (simulation) {
    simulation.dispose();
    simulation = null;
  }
  box_save = null;
  Canvasoperation.box_save = box_save;
  pouqie = 0;
}
let textureMeshArray = [];
let textureMeterialArray = [];
let wirelines = null;
//线框
const wireframeclick = () => {

  if (selectedImage.value == 1) {
    return;
  }
  if (selectedImage.value == 3) {
    textureMeshArray.forEach((mesh, index) => {
      mesh.material = textureMeterialArray[index];
    });
  }
  selectedImage.value = 1;
  const deepCopiedArray = _.cloneDeep(pickableObjects);
  pickableObjects.forEach(element => {
    element.visible = false;
  });
  console.log(scene.clone())
  // Nodeclick.traverseAndCollectMaterials(model, true)
  wirelines = Nodeclick.traverseallMesh(deepCopiedArray)
  console.log(scene.clone())
}
//真实
const reallyclick = () => {
  if (selectedImage.value == 2) {
    return;
  }
  if (selectedImage.value == 1) {
    // Nodeclick.traverseAndCollectMaterials(model, false)
    wirelines.forEach(element => {
      scene.remove(element)
    });
    pickableObjects.forEach(element => {
      element.visible = true;
    });
  }
  else if (selectedImage.value == 3) {
    textureMeshArray.forEach((mesh, index) => {
      mesh.material = textureMeterialArray[index];
    });
  }
  selectedImage.value = 2;
}
//着色
const colorclick = () => {
  if (selectedImage.value == 3) {
    return;
  }
  if (selectedImage.value == 1) {
    wirelines.forEach(element => {
      scene.remove(element)
    });
    pickableObjects.forEach(element => {
      scene.add(element)
    });
  }
  selectedImage.value = 3;
  // ({ TextureNodes: textureMeshArray, materials: textureMeterialArray } = Nodeclick.traverseMaterialsCollectTexture(model));
  // console.log(model)
}
//构建树
let tree = 0;
const treeclick = () => {
  if (tree == 0) {
    treeurl.value = require('@/img/tree.svg')
    isLeftVisible.value = true
    tree = 1;
  }
  else {
    treeurl.value = require('@/img/tree2.svg')
    isLeftVisible.value = false
    tree = 0;
  }

}
let atrribute = 0;
//属性
const atrributeclick = () => {
  if (atrribute == 0) {
    atrributeurl.value = require('@/img/atrribute.svg')
    flag.value = 1
    atrribute = 1;
  }
  else {
    atrributeurl.value = require('@/img/atrribute2.svg')
    flag.value = 0
    atrribute = 0;
  }
}
//背景
const bgcclick = () => {
  bgcdialog.value = true;
}
const bgcConfirm = () => {
  scene.remove(gradientMesh)
  console.log(bgc.value)
  if (bgc.value == 'color') {
    scene.background = new THREE.Color(bgccolor.value)

  }
  bgcdialog.value = false;

}
const bgcCancel = () => {
  bgcdialog.value = false;
}
// const beforeUpload = (file) => {
//   bgc_fileList.push(file.raw)
// }
let axis = 0;
// 添加坐标轴辅助线
let axesHelper = null;
const axisHelperclick = () => {
  if (axis == 0) {
    axisHelperurl.value = require('@/img/axis2.svg')
    axesHelper = new THREE.AxesHelper(100);
    scene.add(axesHelper)
    axis = 1;
  }
  else {
    axisHelperurl.value = require('@/img/axis.svg')
    scene.remove(axesHelper);
    axis = 0;
  }
}
//返回到上一页
const CloseBimtitle = () => {
  loadingInstance.close();
  router.back();//浏览器历史回退
}
//主页
// let home = 0;
const styleclick = () => {
  // pouqieurl.value = require('@/img/pouqie1.svg')
  camera.position.set(center.x, center.y, center.z + distance);
  controls.target.set(0, 0, 0);
  camera.updateProjectionMatrix();
  // if (home == 0) {

  //   home = 1;
  // }
  // else {
  //   pouqieurl.value = require('@/img/pouqie2.svg');
  //   home = 0;
  // }

}
let isDragging = false;  // 标记鼠标是否处于拖拽状态
// let previousMousePosition = new THREE.Vector2();
//监听鼠标按下事件
renderer.domElement.addEventListener('mousedown', function (event) {
  if (event.button === 0) { // 左键
    isDragging = true;
    isClick = true;
    // previousMousePosition.set(event.clientX, event.clientY);
  }
});
// 鼠标移动事件，旋转模型
let mouseraycaster = true;
const newcenter = new THREE.Vector3();
let points = []
renderer.domElement.addEventListener('mousemove', function (event) {
  if (!isDragging) return;
  // if(clickResults.length>0) return;
  //未选择构件，旋转中心就是屏幕中心对应构件上的点
  if (clickobject.length == 0) {
    if (mouseraycaster) {
      const intersects = Canvasoperation.Centermouseraycaster();
      if (intersects.length > 0) {
        newcenter.copy(intersects[0].point)
        mouseraycaster = false;
      }
      //若此时没有找到点击的点，则
      else {
        newcenter.copy(controls.target)
      }
    }

    // newcenter.copy(camera.position)
  }
  //选中构件，默认旋转中心是构件中心
  else {
    if (mouseraycaster) {
      points = [];
      for (let i = 0; i < clickobject.length; i++) {
        points.push(Nodeclick.getBoundingSphereCenterInWorld(clickobject[i][0], clickobject[i][1]))
      }
      newcenter.copy(Nodeclick.computeCentroid(points))
      mouseraycaster = false;

    }

  }
  // console.log(newcenter)
  controls.target.copy(newcenter);
  controls.update();
  isClick = false;
  event.preventDefault();
});
// 鼠标松开事件，结束旋转
renderer.domElement.addEventListener('mouseup', function (event) {
  if (event.button === 0) {
    isDragging = false;
    mouseraycaster = true;
  }
});
// 如果滚出画布则停止旋转
renderer.domElement.addEventListener('mouseleave', function () {
  isDragging = false;
});
function animate() {
  // 更新帧数
  if (stats) {
    stats.update()
  }

  requestAnimationFrame(animate);
}
animate();

</script>
