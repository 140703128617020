
import router from "@/router"
const FileType = {
    GLB: 0,
    RVT: 1,
    PDF: 2,
    PPT: 3,
    DOCX: 4,
    XLSX: 5,
    DWG: 6,
    JPG: 7,
    JPEG: 8,
    PNG: 9,
    AVI: 10,
    MP4: 11,
    GLTF: 12,
    BIN: 13,
    DWF: 14,
    TIFF: 15,
    BMP: 16,
    PPTX: 17,
    OTHER: 18
};


// 定义获取文件类型的方法
function getFileType(type) {
    switch (type) {
        case 'glb':
            return FileType.GLB;
        case 'rvt':
            return FileType.RVT;
        case 'pdf':
            return FileType.PDF;
        case 'ppt':
            return FileType.PPT;
        case 'pptx':
            return FileType.PPTX;
        case 'docx':
            return FileType.DOCX;
        case 'xlsx':
            return FileType.XLSX;
        case 'dwg':
            return FileType.DWG;
        case 'jpg':
            return FileType.JPG;
        case 'jpeg':
            return FileType.JPEG;
        case 'png':
            return FileType.PNG;
        case 'avi':
            return FileType.AVI;
        case 'mp4':
            return FileType.MP4;
        case 'gltf':
            return FileType.GLTF;
        case 'bin':
            return FileType.BIN;
        case 'dwf':
            return FileType.DWF;
        case 'tiff':
            return FileType.TIFF;
        case 'bmp':
            return FileType.BMP;
        default:
            return FileType.OTHER;
    }
}
// 定义根据值获取文件类型的方法
function getFileTypeByValue(value) {
    for (const [key, val] of Object.entries(FileType)) {
        if (val === value) {
            return key; // 返回文件类型字符串
        }
    }
    return 'unknown'; // 如果找不到，返回 unknown
}
function getImageSrc(fileType) {
    switch (fileType) {
        case 0:
            return require('@/img/glb.svg'); // PDF 图标的路径
        case 1:
            return require('@/img/RVT.svg'); // Word 文档图标的路径
        case 2:
            return require('@/img/pdf.svg'); // 默认图标路径
        case 3:
            return require('@/img/ppt.svg'); // PowerPoint 图标的路径
        case 4:
            return require('@/img/word.svg');
        case 5:
            return require('@/img/excel.svg');
        case 6:
            return require('@/img/dwg.svg');
        case 7:
            return require('@/img/JPG图标.svg'); 
        case 8:
            return require('@/img/JPEG.svg'); 
        case 9:
            return require('@/img/png.svg'); 
        case 10:
            return require('@/img/avi.svg');
        case 11:
            return require('@/img/mp4.svg');
        case 12:
            return require('@/img/glTF.svg');
        case 13:
            return require('@/img/二进制.svg'); 
        case 14:
            return require('@/img/DWF.svg');
        case 15:
            return require('@/img/TIFF.svg');
        case 16:
            return require('@/img/BMP图标.svg'); 
        case 17:
            return require('@/img/ppt.svg');
        // 其他文件类型的处理
        default:
            return require('@/img/pdf.svg'); // 默认图标路径
    }
}
function navigateBasedOnFileType(e) {
    sessionStorage.setItem('fileinfo', JSON.stringify(e));
    switch (e.FileType) {
        case 0: {
            router.push({ name: 'bimviewer' });
            break; // 可选：使用 break 结束 case
        }
        case 1: {
            router.push({ name: 'bimviewer' });
            break;
        }
        case 2: {
            router.push({ name: 'pdfviewer' });
            break;
        }
        case 3: 
        case 17:{
            router.push({ name: 'pptxviewer' });
            break;
        }
        case 4: {
            router.push({ name: 'docxviewer' });
            break;
        }
        case 5: {
            router.push({ name: 'excelviewer' });
            break;
        }
        default: {
            console.warn('未知文件类型:', e.FileType);
            break;
        }
    }
}
export { getFileType, getFileTypeByValue, getImageSrc, navigateBasedOnFileType ,FileType};