import MATERIAL from "../js/material.js";
import ThreeExtensions from "../js/threeExtensions.js";
import * as THREE from 'three';
import eventBus from '../js/eventBus.js';
const picking = function (simulation) {

	var intersected = null;
	var mouse = new THREE.Vector2();
	var ray = new THREE.Raycaster();

	var normals = {
		x1: new THREE.Vector3(-1, 0, 0),
		x2: new THREE.Vector3(1, 0, 0),
		y1: new THREE.Vector3(0, -1, 0),
		y2: new THREE.Vector3(0, 1, 0),
		z1: new THREE.Vector3(0, 0, -1),
		z2: new THREE.Vector3(0, 0, 1)
	};
	var clipnormals = {
		x1: new THREE.Vector3(1, 0, 0),
		x2: new THREE.Vector3(-1, 0, 0),
		y1: new THREE.Vector3(0, 1, 0),
		y2: new THREE.Vector3(0, -1, 0),
		z1: new THREE.Vector3(0, 0, 1),
		z2: new THREE.Vector3(0, 0, -1)
	};
	var plane = new THREE.Mesh(new THREE.PlaneGeometry(50, 50, 4, 4), MATERIAL.Invisible);
	simulation.scene.add(plane);
	// let tempaxis = '';
	// const clipplanes = [];
	const clipplanes = new Map();
	if (simulation.box_save) {
		if (simulation.box_save.clipplaneMap) {
			simulation.box_save.clipplaneMap.forEach((value, key) => {
				clipplanes.set(key, value)
			});
		}
	}


	// const save_clipplanes = simulation.renderer.clippingPlanes;
	
	var targeting = function (event) {

		ThreeExtensions.setToNormalizedDeviceCoordinates(mouse, event, window);
		ray.setFromCamera(mouse, simulation.camera);

		var intersects = ray.intersectObjects(simulation.selection.selectables);
		console.log(simulation.selection.selectables)
		if (intersects.length > 0) {
			var candidate = intersects[0].object;
			candidate.guardian.rayOver();
			if (intersected !== candidate) {//检查当前相交的对象 candidate 和上一次相交的对象 intersected 是否相同。
				//如果它们不同，这表示鼠标现在悬停在了一个新的 3D 目标对象上，意味着用户从一个对象移动到了另一个对象上，这需要对新对象做高亮处理，并移除旧对象的高亮效果
				if (intersected !== null) {
					intersected.guardian.rayOut();
				}
				candidate.guardian.rayOver();
				intersected = candidate;
				simulation.renderer.domElement.style.cursor = 'pointer';//如果检测到交集，且当前对象与上次不同，则高亮当前对象并取消上一个对象的高亮。同时更改鼠标光标为指针样式。
				// simulation.throttledRender();

			}

		} else if (intersected !== null) {

			intersected.guardian.rayOut();
			intersected = null;

			simulation.renderer.domElement.style.cursor = 'auto';
			// simulation.throttledRender();

		}

	};

	var beginDrag = function (event) {

		ThreeExtensions.setToNormalizedDeviceCoordinates(mouse, event, window);
		ray.setFromCamera(mouse, simulation.camera);

		var intersects = ray.intersectObjects(simulation.selection.selectables);

		if (intersects.length > 0) {

			event.preventDefault();
			event.stopPropagation();

			simulation.controls.enabled = false;

			var intersectionPoint = intersects[0].point;

			var axis = intersects[0].object.axis;
			if (axis === 'x1' || axis === 'x2') {
				intersectionPoint.setX(0);
			} else if (axis === 'y1' || axis === 'y2') {
				intersectionPoint.setY(0);
			} else if (axis === 'z1' || axis === 'z2') {
				intersectionPoint.setZ(0);
			}
			plane.position.copy(intersectionPoint);
			var newNormal = simulation.camera.position.clone().sub(
				simulation.camera.position.clone().projectOnVector(normals[axis])
			);
			plane.lookAt(newNormal.add(intersectionPoint));

			simulation.renderer.domElement.style.cursor = 'move';
			// simulation.throttledRender();

			var continueDrag = function (event) {
				event.preventDefault();
				event.stopPropagation();

				ThreeExtensions.setToNormalizedDeviceCoordinates(mouse, event, window);

				ray.setFromCamera(mouse, simulation.camera);

				var intersects = ray.intersectObject(plane);
				if (intersects.length > 0) {
					let value = null;
					if (axis === 'x1' || axis === 'x2') {
						value = intersects[0].point.x;
					} else if (axis === 'y1' || axis === 'y2') {
						value = intersects[0].point.y;
					} else if (axis === 'z1' || axis === 'z2') {
						value = intersects[0].point.z;
					}
					simulation.selection.setValue(axis, value);
					let that = simulation.selection;
					let temp_value = null;
					if (axis === 'x1') {
						temp_value = that.limitLow.x
					} else if (axis === 'x2') {
						temp_value = that.limitHigh.x
					} else if (axis === 'y1') {
						temp_value = that.limitLow.y
					} else if (axis === 'y2') {
						temp_value = that.limitHigh.y
					} else if (axis === 'z1') {
						temp_value = that.limitLow.z

					} else if (axis === 'z2') {
						temp_value = that.limitHigh.z
					}
					const temp_normal = clipnormals[axis];
					let constant = null;
					// 检查不同分量是否为负值
					if (temp_normal.x >= 0 && temp_normal.y >= 0 && temp_normal.z >= 0) {
						constant = (-1) * temp_value;
					}
					else {
						constant = temp_value;
					}

					const clipplane = new THREE.Plane(temp_normal, constant);
					clipplanes.set(axis, clipplane)
					// if (tempaxis == axis) {
					// 	save_clipplanes.pop()
					// 	save_clipplanes.push(clipplane)
					// }
					// else {
					// 	save_clipplanes.push(clipplane)
					// 	tempaxis = axis
					// }
					// console.log(save_clipplanes)
					const save_clipplanes = [];
					clipplanes.forEach((value) => {
						save_clipplanes.push(value)
					});
					simulation.renderer.clippingPlanes = save_clipplanes
					// console.log(clipplanes,save_clipplanes)
					simulation.throttledRender();
					eventBus.emit('clickStatusRefresh');
				}

			};

			var endDrag = function () {

				simulation.controls.enabled = true;

				simulation.renderer.domElement.style.cursor = 'pointer';

				document.removeEventListener('mousemove', continueDrag, true);
				document.removeEventListener('touchmove', continueDrag, true);

				document.removeEventListener('mouseup', endDrag, false);
				document.removeEventListener('touchend', endDrag, false);
				document.removeEventListener('touchcancel', endDrag, false);
				document.removeEventListener('touchleave', endDrag, false);

			};

			document.addEventListener('mousemove', continueDrag, true);
			document.addEventListener('touchmove', continueDrag, true);

			document.addEventListener('mouseup', endDrag, false);
			document.addEventListener('touchend', endDrag, false);
			document.addEventListener('touchcancel', endDrag, false);
			document.addEventListener('touchleave', endDrag, false);

		}

	};
	//注册事件监听器
	simulation.renderer.domElement.addEventListener('mousemove', targeting, true);
	simulation.renderer.domElement.addEventListener('mousedown', beginDrag, false);
	simulation.renderer.domElement.addEventListener('touchstart', beginDrag, false);
	simulation.targeting = targeting;
	simulation.beginDrag = beginDrag;
	simulation.endDrag = beginDrag;
	simulation.clipplanes = clipplanes;
};
export default picking;
//允许用户通过鼠标和触摸选择和拖动对象。它使用射线投射技术来检测与可选择对象的交互，并使用平面逻辑来处理拖动操作
