import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router.js"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import urls from './api/api.js'
import request from './api/request.js'
import createStore from './store/index.js'
import tips from './config/ElMessage.js'
import "amfe-flexible/index.js";
import 'element-plus/theme-chalk/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import preview from 'vue3-image-preview';
const app = createApp(App)
app.config.globalProperties.$urls = urls;//请求地址
app.config.globalProperties.$request = request;//请求方法
app.config.globalProperties.$tips = tips;//提示
app.use(router);
app.use(ElementPlus, { locale: zhCn });
app.use(createStore)
app.use(preview);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.mount('#app')

