import * as THREE from 'three';

class PlaneGeometry extends THREE.BufferGeometry {
    constructor(v0, v1, v2, v3) {
        super(); // 调用父类构造函数

        // 定义顶点数组
        const vertices = new Float32Array([
            v0.x, v0.y, v0.z,
            v1.x, v1.y, v1.z,
            v2.x, v2.y, v2.z,
            v3.x, v3.y, v3.z
        ]);
        
        // 设置顶点属性
        this.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        // 定义索引数组
        const indices = new Uint16Array([
            0, 1, 2, // 第一个三角形
            0, 2, 3  // 第二个三角形
        ]);

        // 设置索引
        this.setIndex(new THREE.BufferAttribute(indices, 1));

        // 计算法线（在 buffer geometry 中）
        this.computeVertexNormals();
    }
}

export default PlaneGeometry;

