<template>
    <div id="notice_outter" v-if="notice_tableData.arr.length">
        <el-table :data="notice_tableData.arr" style="width: 100%;" @row-click="handleRowClick" :row-class-name="tableRowClassName">
            <el-table-column fixed prop="InformType" label="消息类型" :width="getDynamicWidth(250)" align="center">
                <template #default="{ row }">
                    <span>
                        {{ informtype(row.InformType) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="Content" label="内容" :width="getDynamicWidth(722)" align="center" />
            <el-table-column prop="BeinviteTime" label="时间" :width="getDynamicWidth(370)" align="center">
                <template #default="{ row }">
                    <span>
                        {{ formatDate(row.BeinviteTime) }}
                    </span>
                </template></el-table-column>
            <el-table-column fixed="right" label="操作" align="center">
                <template #default="{ row }">
                    <template v-if="row.InformType == 0">
                        <el-button link type="primary" size="small" @click="handleAccept(row._id)">
                            接受
                        </el-button>
                        <el-button link type="danger" size="small" @click="handleReject(row._id)">
                            拒绝
                        </el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div id="notice_outter" v-else>
        <el-empty description="暂无消息" class="empty_outter" :image="emptyimageurl" />

    </div>
    <el-dialog v-model="notice_confirm" title="项目信息" width="700" :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="projectimage">
            <el-image style="width: 120px; height: 120px" :src="xmimage" :fit="fit" />
        </div>

        <el-descriptions title="" column="2">
            <el-descriptions-item label="项目类型 :">公建</el-descriptions-item>
            <el-descriptions-item label="项目面积 :">1000m²</el-descriptions-item>
            <el-descriptions-item label="项目名称 :">XXXX项目名称</el-descriptions-item>
            <el-descriptions-item label="客户编号 :">123456
            </el-descriptions-item>
            <el-descriptions-item label="项目地址 :">浙江省 杭州市 西湖区 XX新村10幢
            </el-descriptions-item>
            <el-descriptions-item></el-descriptions-item>
            <el-descriptions-item label="开工日期 :">2023-12-20</el-descriptions-item>
            <el-descriptions-item label="竣工日期 :">2023-12-20</el-descriptions-item>
        </el-descriptions>
        <el-descriptions column="1">
            <el-descriptions-item label="建设单位 :">建发房地产集团有限公司</el-descriptions-item>
            <el-descriptions-item label="设计单位 :">建发房地产集团设计有限公司</el-descriptions-item>
            <el-descriptions-item label="监理单位 :">建发房地产集团监理有限公司</el-descriptions-item>
            <el-descriptions-item label="施工单位 :">建发房地产集团施工有限公司</el-descriptions-item>
            <el-descriptions-item label="备注 :">苟富贵会根据杨靖宇具有会计与苦呀口语看卡</el-descriptions-item>
        </el-descriptions>




        <template #footer>
            <div class="dialog-footer">
                <el-button @click="notice_confirm_click" type="primary">接受</el-button>
                <el-button type="danger" @click="notice_click" text>
                    拒绝
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import "../css/notice.css"
export default {
    name: 'NoticeItem',
}
</script>
<script setup>
import { reactive, ref, onMounted, defineProps, watchEffect, computed, getCurrentInstance } from 'vue'
import { ElMessageBox } from 'element-plus'
import formatDate from "../config/formatDate.js"
import { useStore } from 'vuex'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import eventBus from '../js/eventBus.js';
const user = JSON.parse(sessionStorage.getItem('user'))
const props = defineProps({
    msg: String,
    noticelist: {
        type: Array,
        default: () => [],
    },
});
const { proxy } = getCurrentInstance()
const emptyimageurl = require('@/img/暂无任务.svg')
const store = useStore();
const notice_confirm = ref(false);
const notice_tableData = reactive(
    {
        arr: []
    }
)
onMounted(() => {
    notice_tableData.arr = props.noticelist;
    console.log(props.noticelist);
})
const getDynamicWidth = (px) => {
    // 根据窗口的宽度动态计算列宽
    const ratio = window.innerWidth / 2560; // 假设 1920 是你设计的基准宽度
    return Math.max(px * ratio, 10) + 'px'; // 保证最小宽度不小于原始值的 80%
}
const prolisttype = computed(() => store.state.noticeitemStatus);
watchEffect(() => {
    if (prolisttype.value == "second") {
        notice_tableData.arr = props.noticelist.filter(item => item.IsRead == false);
    }
    else if (prolisttype.value == "thired") {
        notice_tableData.arr = props.noticelist.filter(item => item.IsRead == true);
    }
    else {
        notice_tableData.arr = props.noticelist;
    }
});
const informtype = (type) => {
    switch (type) {
        case 1: return "任务邀请";
        case 0: return "项目邀请";
    }
}
const notice_confirm_click = () => {
    notice_confirm.value = false;
    //接受
}
const notice_click = () => {
    notice_confirm.value = false;
    //拒绝
}
const handleAccept = (id) => {
    ElMessageBox.confirm(
        '确认加入此项目?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            roundButton: true,
        }
    )
        .then(async () => {
            await JoinBIMARintergrationInform(id, true)

        })

}
const handleReject = (id) => {
    ElMessageBox.confirm(
        '确认拒绝加入此项目?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            roundButton: true,
        }
    )
        .then(async () => {
            await JoinBIMARintergrationInform(id, false)
        })
}
const tableRowClassName = ({ row }) => {
    if (!row.IsRead) {
        return 'read-row';
    }
    return '';
};
const JoinBIMARintergrationInform = async (id, flag) => {
    const urldata = new URLSearchParams();
    urldata.append('Account', user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('InformId', id);
    urldata.append('isJoin', flag);
    const res = await new proxy.$request(proxy.$urls.m().JoinBIMARintergrationInform, urldata).modepost();
    console.log(res)
    if (!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            new proxy.$tips("操作成功", "success").Mess_age()
            eventBus.emit('decrement');
            eventBus.emit('loadData');
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }
    }
}
const handleRowClick = async (row) => {
    console.log(row)
    notice_confirm.value = true;
    if (row.InformType == 0) {//任务邀请
        return;
    }
    if (!row.IsRead) {
        //未读
        //发送请求
        const urldata = new URLSearchParams();
        urldata.append('Account', user.emailname);
        urldata.append('Token', getCookie('token'));
        urldata.append('InformId', row._id);
        const res = await new proxy.$request(proxy.$urls.m().ReadInforms, urldata).modepost();
        console.log(res)
        if (!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                new proxy.$tips("操作成功", "success").Mess_age();
                eventBus.emit('decrement');
                eventBus.emit('loadData');
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()

            }
        }

    }
}
</script>
