<template>
    <el-row class="tac">
        <el-col>
            <el-menu :default-active="side_default_active" class="el-menu-vertical-demo" text-color="#DDDDDD"
                @select="side_select">
                <el-menu-item index="1" class="menu-item-centered sidemenu_top">
                    <img class="custom-icon" :src="require('@/img/renwu.svg')">
                    <span>任务管理</span>
                </el-menu-item>
                <el-menu-item index="2" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/wendang2.svg')">
                    <span>文档管理</span>
                </el-menu-item>
                <!-- <el-menu-item index="3" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/moxing.svg')">
                    <span>模型管理</span>
                </el-menu-item> -->
                <el-menu-item index="4" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/chengben.svg')">
                    <span>成本管理</span>
                </el-menu-item>
                <el-menu-item index="5" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/zhiliang.svg')">
                    <span>质量管理</span>
                </el-menu-item>
                <el-menu-item index="6" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/process.svg')">
                    <span>进度管理</span>
                </el-menu-item>
                <el-menu-item index="7" class="menu-item-centered">
                    <img class="custom-icon" :src="require('@/img/chengyuan.svg')">
                    <span>成员管理</span>
                </el-menu-item>
            </el-menu>
        </el-col></el-row>
    <el-button text type="Default" class="listbutton" size="large" bg @click="returnproject">项目列表</el-button>
    <div class="xmname">
        <div class="xmname_left"></div>

        <div class="xmname_right">
            {{ projectinfo.CivilName }}
        </div>

    </div>
    <router-view></router-view>

</template>
<script>
import '../css/sidemenu.css'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
export default {
    name: 'SideMenu',
    props: {
        msg: String
    },
}
</script>

<script setup>
const router = useRouter();
//菜单默认选择状态
const side_default_active = ref('1');
const projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"))
// eslint-disable-next-line no-unused-vars
const side_select = (index, path) => {
    sessionStorage.setItem('menuid', index);
    switch (index) {
        case '1': router.push({
            name: "renwuinfo"
        });
            break;
        case '2': router.push({
            name: "file"
        }
        ); break;
        case '4': router.push({
            name: "quality"
        }
        ); break;
        case '5': router.push({
            name: "quality"
        }
        ); break;
        case '6': router.push({
            name: "quality"
        }
        ); break;
        case '7': router.push({
            name: "member"
        }
        ); break;

    }

}
// 刷新页面时，菜单项的选中状态不变
onMounted(() => {
    
    if (sessionStorage.getItem('menuid')) {
        side_default_active.value = JSON.parse(sessionStorage.getItem('menuid'))
        const index = side_default_active.value
        if (index == 1) {
            router.push({
                name: "renwuinfo"
            })
        }
        else if (index == 2) {
            router.push({
                name: "file"
            }
            )

        }
        else if (index == 7) {
            router.push({
                name: "member"
            }
            )

        }
    }

})
const returnproject = () => {
    router.push({
        name: "projectList"
    })
}
</script>