<template>
  <section>
    <div class="title">
      <img src="../img/navigatelogo.svg" alt="">
      <a class="linklogin" @click="loginplus">立即登录</a>
    </div>
    <form action="" id="forget-confirm">
      <div class="two_inner">
        <div class="two_passwordtext">忘记密码</div>
        <span id="confirm-email">注册码已发送至：{{ confirm_email }}</span><br/>
        <div class="two_text">
          验证码有效期为10分钟
        </div>
        <el-input v-model="verifycode" type="text" placeholder=" 请输入验证码" class="register_email" />
        <el-input v-model="password" type="password" show-password placeholder="设置密码(长度必须是6~30位字符)"  class="register_verify_one" />
        <el-input v-model="passwordplus" type="password" show-password placeholder="确认密码"  class="register_verify_one" />
        <el-button type="primary" @click="registerplus" class="register_submit_one">下一步</el-button>
      </div>
    </form>
  </section>
</template>
<script>
import '../css/forgettwo.css'
import { reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userinfo = reactive({
      verifycode: "",
      password: "",
      passwordplus: ""
    })
    const registerplus = () => {
      if (userinfo.verifycode == "" || userinfo.password == "" || userinfo.passwordplus == "") {
        alert("请完善表单");
        return;
      }
      else if (userinfo.password.length < 6 || userinfo.password.length > 30 || userinfo.passwordplus.length < 6 || userinfo.passwordplus.length > 30) {
        alert("密码的位数必须在6~30之间")
        return;
      }
      else if (userinfo.password == userinfo.passwordplus) {
        router.push({
          name: 'forgetthree'
        })
      }
      else{
        alert("两次密码输入不一致")
        return;
      }
    }
    const confirm_email = ref(store.state.emailname);
    const loginplus = () => {
      router.push({
        name: 'login'
      })
    }
    return {
      confirm_email,
      registerplus,
      ...toRefs(userinfo),
      loginplus
    }
  }
}
</script>
<style scoped></style>