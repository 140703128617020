import { ElMessage } from 'element-plus'
const tips=class{
    constructor(message,type="success"){//默认值为成功
        this.message=message
        this.type=type
    }
    Mess_age(){
        ElMessage({
            message:this.message,
            type:this.type,
            duration:1500
        })
    }
}
export default tips