<template>
    <div id="pro_outter" v-if="prolist1.arr.length">
        <div class="pro_a" v-for="item in prolist1.arr" :key="item._id" @click="projectclick($event, item)">
            <div id="pro_out">
                <div class="type">{{ projecttype.getLabel(item.CivilType) }} </div>
                <div class="vertical">|</div>
                <div class="mingcheng">{{ item.CivilName }}</div>
                <div class="vertical">|</div>
                <div class="mianji">{{ item.CivilSquare }}m²</div>
                <!-- 我创建的项目 -->
                <div class="img" v-if="item.OwnerAccount == account">
                </div>
                <!-- 别人创建的项目 -->
                <div class="img" v-else>
                    <img src="../img/xmexit.svg" class="exit" @click="exitxm" />
                </div>
            </div>
            <div class="pro_body">
                <div class="first"> <el-image class="pro_img"
                        :src="`https://bim.bfine-tech.com${fileconvert.convertWindowsPathToUnixPath(item.imageUrl)}`"
                        :fit="fit"
                         />
                </div>
                <div class="second">
                    <el-descriptions title="" column="1">

                        <el-descriptions-item>
                            <span style="color: #999999;">项目编号 :</span> {{ item._id }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <span style="color: #999999;">建设单位 :</span> {{ item.ConstructionCompany }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <span style="color: #999999;">咨询单位 :</span> {{ item.SupervisionCompany }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <span style="color: #999999;">开工日期 :</span> {{ formatDate(item.CommenceDate).split(" ")[0]
                            }}
                        </el-descriptions-item>
                    </el-descriptions>

                    <div class="position">
                        <img src="../img/position.svg" class="position_img" />
                        {{ item.CivilArea }}
                    </div>
                </div>
                <div class="thired">
                    <el-descriptions title="" column="1">
                        <el-descriptions-item>
                            <span style="color: #999999;">客户编号 :</span> {{ item.CivilInternalNumber }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <span style="color: #999999;">设计单位 :</span>{{ item.DesignCompany }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <span style="color: #999999;">施工单位 :</span> {{ item.ConstructionContractor }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <span style="color: #999999;">竣工日期 :</span> {{ formatDate(item.CompletionDate).split(" ")[0]
                            }}
                        </el-descriptions-item>

                    </el-descriptions>
                    <div class="beizhu">
                        <span style="color: #999999;">备注 :</span> {{ item.Description }}
                    </div>
                </div>
                <div class="forth">
                    <el-button type="primary" plain @click="invite($event, item._id)">
                        <el-icon>
                            <User />
                        </el-icon>成员邀请
                    </el-button>
                </div>
            </div>
        </div>
    </div>
    <div id="pro_outter" v-else>
        <el-empty description="暂无项目" class="empty_outter" :image="emptyimageurl" />

    </div>
    <el-dialog v-model="cyinvite" title="成员邀请" width="500" venter @open="getUserList" :close-on-click-modal="false">
        <el-form :model="cyform" :rules="rules" ref="cyinviteForm">
            <el-form-item label="用户账号" :label-width="formLabelWidth" prop="emailname">
                <el-input v-model="cyform.emailname" autocomplete="off" placeholder="请输入邀请人账号" required />
            </el-form-item>
            <el-form-item label="选择角色" :label-width="formLabelWidth" prop="quanxian">
                <el-select v-model="cyform.quanxian" placeholder="请选择" @change="handleSelectChange">
                    <el-option v-for="item in role_options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="cyinvitecancel">取消</el-button>
                <el-button type="primary" @click="inviteclick">
                    邀请
                </el-button>
            </div>
        </template>
    </el-dialog>
    <el-dialog v-model="exitshow" title="退出" width="500" :before-close="handleClose" :close-on-click-modal="false">
        <div class="exit" style="display: grid; place-items: center; height: 100%;">
            <span>确认退出项目</span>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="exitshow = false" type="danger" style="width:80px">是</el-button>
                <el-button type="primary" @click="exitshow = false" style="width:80px">
                    取消
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import '../css/projectitem.css'
import { useRouter } from 'vue-router'
import { reactive, ref, onMounted, defineProps, computed, watchEffect, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import formatDate from "../config/formatDate.js"
import { useStore } from 'vuex'
import projectType from '../config/projecttype.js'
import { getCookie } from '../store/cookieUtil.js';
import handleError from '../config/HandleError.js'
import fileConvert from '@/js/fileConvert.js';
export default {
    name: 'ProjectItem',
}
</script>
<script setup>
const props = defineProps({
    msg: String,
    prolist: {
        type: Array,
        default: () => [],
    },
});
const fileconvert = new fileConvert();
const { proxy } = getCurrentInstance()
const account = JSON.parse(sessionStorage.getItem('user')).emailname
const projecttype = new projectType();
const store = useStore();
const router = useRouter();
const role_options = reactive([])
const prolist1 = reactive({
    arr: []
})
const emptyimageurl = require('@/img/暂无任务.svg')
const exitshow = ref(false)
const cyform = reactive(
    {
        emailname: "",
        quanxian: ""
    }
)
const user = JSON.parse(sessionStorage.getItem('user'))
const cyinvite = ref(false)
var status = 0;
let temp_id = 0;
const projectclick = (e, item) => {
    router.push({
        name: 'sidemenu',
    })
    sessionStorage.setItem('projectinfo', JSON.stringify(item))
}
const invite = (e, id) => {
    temp_id = id;
    cyinvite.value = true,
        e.stopPropagation();


}
const rules = reactive(
    {
        emailname: [
            {
                required: true, message: "邮件名不能为空", trigger: "blur"
            },
            // {
            //     type: "email", message: "请输入正确的邮件名", trigger: "blur"
            // },
        ],
        quanxian: [
            {
                required: true, message: "角色不能为空", trigger: "change"
            }
        ]

    }
);

const cyinviteForm = ref(null)
const inviteclick = async () => {

    await cyinviteForm.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage.error("请完善表单，再提交！");
            return false;
        }
        const urldata = new URLSearchParams();
        urldata.append('Token',getCookie('token'));
        urldata.append('BeUserName', cyform.emailname);
        urldata.append('ProjectId',temp_id);
        urldata.append('Account', user.emailname);
        urldata.append('AuthorityId',cyform.quanxian);
        urldata.append('RoleName', selectedLabel.value);
        const res = await new proxy.$request(proxy.$urls.m().AddUser,urldata).modepost();
        console.log(res)
        if(!res) return;
        if (res.status != 200) {
            new proxy.$tips(res.data.message, "warning").Mess_age()
            return;
        }
        else {
            if (res.data && res.data.Error == 0) {
                new proxy.$tips("添加成功", "success").Mess_age()
                cyinvite.value = false;
            }
            else {
                const errorMessage = handleError(res);
                new proxy.$tips(errorMessage, "error").Mess_age()

            }
        }

    })

    //发送请求
}

const cyinvitecancel = () => {
    cyinvite.value = false;
    cyinviteForm.value.resetFields()
}
const exitxm = (e, item) => {
    e.stopPropagation();
    exitshow.value = true;
    sessionStorage.setItem('exitxm', item)
}
const typeselected = computed(() => store.state.typeselected);
watchEffect(() => {
    if (!props.prolist) {
        throw new Error('prolist is not provided');
    }
    else {
        console.log(props.prolist, status, typeselected.value)
        prolist1.arr = props.prolist.filter(item =>
            typeselected.value === -1
                ? item.FinishState === status
                : item.FinishState === status && item.CivilType === typeselected.value
        );
    }
});
const prolisttype = computed(() => store.state.prolisttype);
watchEffect(() => {

    console.log(prolisttype.value, props.prolist)
    if (prolisttype.value === 'first') {
        status = 0;
    }
    else {
        status = 1;
    }
    if (!props.prolist) {
        throw new Error('prolist is not provided');
    }
    else {
        prolist1.arr = props.prolist.filter(item => item.FinishState === status);
    }
    console.log(prolist1)
});
const searchkeyed = computed(() => store.state.searchkey);
watchEffect(() => {
    // 模糊匹配，过滤失败，有问题`
    prolist1.arr = props.prolist.filter(item => {
        return item.CivilName.toLowerCase().includes(searchkeyed.value.toLowerCase())
    })
});

onMounted(() => {
    //默认显示进行中的数据
    //将数组分开，默认显示进行中的项目
    if (!props.prolist) {
        throw new Error('prolist is not provided');
    }
    else {
        prolist1.arr = props.prolist.filter(item => item.FinishState === 0);
    }

})
// function typefun(val) {
//     let type = val;//后续监控type的值，发生改变时改变prolist1的值
//     console.log(type)

// }
const getUserList = async () => {
    const urldata = new URLSearchParams();
    urldata.append('Account',user.emailname);
    urldata.append('Token', getCookie('token'));
    urldata.append('ProjectId', temp_id);
    const res = await new proxy.$request(proxy.$urls.m().GetRole,urldata).modepost();
    if(!res) return;
    if (res.status != 200) {
        new proxy.$tips(res.data.message, "warning").Mess_age()
        return;
    }
    else {
        if (res.data && res.data.Error == 0) {
            role_options.length = 0;
            res.data.Permissions.forEach(role => {
                role_options.push({
                    label: role.RoleName,
                    value: role._id
                });
            });
        }
        else {
            const errorMessage = handleError(res);
            new proxy.$tips(errorMessage, "error").Mess_age()

        }
    }


}
const selectedLabel = computed(() => {
    const selected = role_options.find(item => item.value === cyform.quanxian);
    return selected ? selected.label : '未选择'; // 如果未选择，返回默认值
});
</script>
