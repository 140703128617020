import * as THREE from 'three';

const UNIFORMS = {
    clipping: {
        color: { type: "c", value: new THREE.Color(0x3d9ecb) },
        clippingLow: { type: "v3", value: new THREE.Vector3(0, 0, 0) },
        clippingHigh: { type: "v3", value: new THREE.Vector3(0, 0, 0) }
    },

    caps: {
        color: { type: "c", value: new THREE.Color(0xf83610) }
    }

};

// 导出 CAPS_UNIFORMS 对象
export default UNIFORMS;

