<template>
  <el-empty description="正在开发中，敬请期待" class="empty"/>
</template>

<script>
import "../css/quality.css"
export default {
  name: 'QualityA',
  props: {
      msg: String
  },
}
</script>

<style></style>