// schedule.js
const SCHEDULE = {

    postpone: function (callback, context, wait) {
        return function (...args) { // 使用 rest 参数来处理任意参数
            setTimeout(function () {
                callback.apply(context, args); // 将上下文和参数传递给回调
            }, wait);
        };
    },

    deferringThrottle: function (callback, context, wait) {
        let blocked = false; // 是否正在被调用
        let deferredCalls = false; // 是否有待处理的调用
        let args; // 暂存参数

        // 执行回调函数的内部函数
        const execute = function (argsToExecute) {
            callback.apply(context, argsToExecute); // 执行回调
            setTimeout(function () {
                if (deferredCalls) { // 检查是否有待处理的调用
                    deferredCalls = false; // 重置标志
                    execute(args); // 重新执行
                } else {
                    blocked = false; // 释放 blocked 状态
                }
            }, wait);
        };

        return function (...callArgs) { // 使用 rest 参数来处理任意参数
            if (blocked) {
                args = callArgs; // 储存参数
                deferredCalls = true; // 设置待处理情况
                return; // 返回，等待下次调用
            } else {
                blocked = true; // 设置为正在被调用
                deferredCalls = false; // 确保没有待处理调用
                execute(callArgs); // 执行回调
            }
        };
    }

};

export default SCHEDULE;
