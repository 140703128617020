<template>
      <section>
    <div class="title">
      <img src="../img/navigatelogo.svg" alt="">
      <a  class="linklogin" @click="loginplus">立即登录</a>
    </div>
    <form action="" class="login-view">
      <div class="three_inner">
        <div class="image"><img src="../img/registersuccess.svg"></div>
        <div class="succ">
          <div class="text">恭喜你重置密码成功！</div>
        </div>
        <div class="zhanghao">
          登录账号:{{ confirm_email }}
        </div>
        <input type="button" value="立即登录" @click="loginplus"><br>
      </div>
      
    </form>
  </section>
</template>
<script>
import '../css/forgetthree.css'
import { ref} from 'vue'
import { useRouter } from 'vue-router'
import {useStore} from 'vuex'
export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    setup(){
      const router = useRouter();
      const store=useStore();
      const confirm_email=ref(store.state.emailname)
      const loginplus=()=>{
        router.push({
              name:'login'
            })
      }
      return {
        confirm_email,
        loginplus
      }
    }
  }
  </script>
  <style scoped>

</style>