//放置所有的请求接口地址
const url = "/api"
const urls = class {
    static m() {
        const login = `${url}/login`
        const register = `${url}/Register`
        const CreateBIM=`${url}/CreateBIM`
        const GetBIM=`${url}/GetBIM`
        const AddUser=`${url}/AddUser`
        const GetUser=`${url}/GetUser`
        const DeleteUser=`${url}/DeleteUser`
        const AddAssigment = `${url}/AddAssigment`
        const GetAssigment=`${url}/GetAssigment`
        const GetBIMFile=`${url}/GetBIMFile`
        const UploadFile=`${url}/UploadFile`
        const DownloadFile=`${url}/DownloadFile`
        const UploadFileShowPath=`${url}/UploadFileShowPath`
        const DeleteAssigment=`${url}/DeleteAssigment`
        const SubmitAssigment=`${url}/SubmitAssigment`
        const CheckAssigment=`${url}/CheckAssigment`
        const CreateRole=`${url}/CreateRole`
        const GetRole=`${url}/GetRole`
        const ModifyAssigment=`${url}/ModifyAssigment`
        const DeleteRole=`${url}/DeleteRole`
        const ModifyUser=`${url}/ModifyUser`
        const ModifyRole=`${url}/ModifyRole`
        const GetFolder=`${url}/GetFolder`
        const CreateFolder =`${url}/CreateFolder`
        const ModifyFolder=`${url}/ModifyFolder`
        const DeleteFolder=`${url}/DeleteFolder`
        const DeleteFile=`${url}/DeleteFile`
        const UpdateFile=`${url}/UpdateFile`
        const GetInforms=`${url}/GetInforms`
        const JoinBIMARintergrationInform=`${url}/JoinBIMARintergrationInform`
        const ReadInforms=`${url}/ReadInforms`
        const FinishAssigment=`${url}/FinishAssigment`
        return{
            login,register,CreateBIM,GetBIM,AddUser,GetUser,DeleteUser,AddAssigment,GetAssigment
            ,GetBIMFile,UploadFile,DownloadFile,UploadFileShowPath,DeleteAssigment,SubmitAssigment,
            CheckAssigment,CreateRole,ModifyAssigment,GetRole,DeleteRole,ModifyUser,ModifyRole,
            GetFolder,CreateFolder,ModifyFolder,DeleteFolder,DeleteFile,UpdateFile,GetInforms,JoinBIMARintergrationInform,ReadInforms,FinishAssigment
        }
    }
}
export default urls